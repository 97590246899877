import * as React from 'react';
import Widget from '../components/Widget';
import {Box, Flex} from 'rebass';
import styled from 'styled-components';

import hardhat_icon from '../resources/images/hardhat_icon.png';
import electrician_icon from '../resources/images/electrician_icon.png';
import plumber_icon from '../resources/images/plumber_icon.png';
import carpenter_icon from '../resources/images/carpenter_icon.png';
import hvac_icon from '../resources/images/hvac_icon.png';

import {createRoot} from 'react-dom/client';

import {JobDetailModal} from './JobsDetail';
import Button from '../components/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faMagnifyingGlass, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

export const icons = {
    'electrician': electrician_icon,
    'plumber': plumber_icon,
    'carpenter': carpenter_icon,
    'hvac': hvac_icon,
   "mason" : hardhat_icon,
   "painter": hardhat_icon ,
   "pipefitter": hardhat_icon ,
    "steelworker": hardhat_icon ,
    "cranlermaker": hardhat_icon ,
     "drie_operator": hardhat_icon ,
     "boiller":hardhat_icon , 
     "taper":hardhat_icon
}

const SeeAllButon = styled(Box)`

`;

const JobsContainer = styled(Box)`
    max-height: 800px;
    overflow: scroll;
`;

export const JobIcon = styled(Box)`
    width: 35px;
    height: 35px;
    min-width: 35px !important;
    background: url(${props => icons[props.trade] || hardhat_icon}) no-repeat center/contain;
`;

const JobTitle = styled(Box)`
&&&{

    flex-grow:1;
    color: #5ac6ed; 
    font-weight: 600;
    margin-bottom: 8px;
    cursor: pointer;
}
`;
const JobSubtitle = styled(Box)`
    font-size:12px;
    text-transform: capitalize;
`;
const JobCompensation = styled(Box)`
font-size:12px;
`;
const MoreDetails = styled(Box)`
    color: #5ac6ed;
    font-size: 12px;
    cursor: pointer;
`;

const SearchBar = styled.input`
position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: calc(100% - 96px);
    border: 1px solid #f2f2f2;
    border-radius: 100px;
    padding: 0 16px;
    font-size: 16px;
    max-width: 0%;
    opacity: 0;
    transition: all .5s ease-in-out;
    pointer-events: none;
    &.toggled {
        max-width: 1000px;
        opacity: 1;
        pointer-events: all;
    }
`

const trades = [
   "Carpenter" ,"Electrician" , "Mason" , "Stone Mason" , "Plumber" ,"Painter" ,"Pipefitter" , "Steelworker" ,"Crane Operator" , "Boilermaker" ,"Driller" , "Taper", "HVAC"
]

export const findJobTrade = (job) => {
    let jobTrade = 'carpenter';
    trades.forEach(trade => {
        const regex = new RegExp(trade, "ig")
        console.log(regex, job.title)
        if (regex.test(job.title) || regex.test(job.description)) {
            console.log(trade)
            jobTrade = trade.toLowerCase();
        }
    });
    return jobTrade
}

const JobsSearch = ({setJobs, jobs}) => {
    const [toggled, setToggled] = React.useState(false);

    const searchJobs = (query) => {
        console.log(query);
        if (query === '' || query === ' ') {
            fetch("https://workingpro.co/wp-json/wp/v2/job").then(r => r.json()).then(data => {
                setJobs([]);
                let newJobs = data.map(job => {
                    let newJob = {};
                    let iframe = document.createElement("iframe");
                    iframe.srcdoc = job.content.rendered;
                    document.body.appendChild(iframe);

                    iframe.onload = ()=>{
                        let jobData = {...iframe.contentWindow.workingpro_job, ...job.meta};
                        jobData.original = iframe.contentDocument.body.innerHTML;
                        console.log(jobData);
                        setJobs((jobsArray) => {return [...jobsArray, jobData]})
                        iframe.remove()
                    }
        
                    function returnJob(jobData){
                        return jobData;
                    }
                })
            })
        } else {
            setJobs([]);
        }
       
        fetch("https://workingpro.co/wp-json/wp/v2/job?search="+query).then(r => r.json()).then(data => {
            setJobs([]);
            const regex = /per hour|\/hour/gi;
            const regex2 = /based on experience/gi;
            let newJobs = data.map(job => {
                let newJob = {};
                let iframe = document.createElement("iframe");
                iframe.srcdoc = job.content.rendered;
                document.body.appendChild(iframe);
               
      
                iframe.onload = ()=>{
                    let jobData = {...iframe.contentWindow.workingpro_job, ...job.meta,  id: job.id};
                    jobData.coordinates = jobData.coordinates.split(" ");
                    jobData.coordinates = [parseFloat(jobData.coordinates[1]),parseFloat(jobData.coordinates[0])]
                    if (typeof jobData.compensation === "object") {
                        jobData.compensation[1] = jobData.compensation[1].replace(regex, "/hr");
                    } else {
                        jobData.compensation = jobData.compensation.replace(regex2, "Experience Based");
                    }
                    jobData.original = iframe.contentDocument.body.innerHTML;
                    console.log(jobData);
                    setJobs((jobsArray) => {return [...jobsArray, jobData]})
                    iframe.remove()
                }
    
                function returnJob(jobData){
                    return jobData;
                }
            })
        })
    }

    return <Flex>
        <SearchBar className={toggled ? 'toggled' : ""} placeholder="Search..." onChange={(e) => {
         //   console.log(e.currentTarget.value);
            searchJobs(e.currentTarget.value);
        }}/>
       {toggled ? <FontAwesomeIcon style={{position:"relative"}} fontSize={"24px"} icon={faClose} onClick={() => {setToggled(!toggled)}}/> : <FontAwesomeIcon  style={{position:"relative"}}  fontSize={"24px"} icon={faMagnifyingGlass} onClick={() => {setToggled(!toggled)}}/>}
    </Flex>
}
const JobsWidget = (props) => {

    const [jobs, setJobs] = React.useState([]);
    const [locationFilter, setLocationFilter] = React.useState("");
    const [tradeFilter, setTradeFilter] = React.useState(" ");
    const [sortBy, setSortBy] = React.useState("newest");
    //const [query, setQuery] = React.useState("");

    const query = `${locationFilter !== " " ? locationFilter : ""} ${tradeFilter !== " " ? tradeFilter : ""}`;

    React.useEffect(()=>{
       // if (jobs.length === 0) {
            fetch("https://workingpro.co/wp-json/wp/v2/job?search="+query).then(r => r.json()).then(data => {

            const regex = /per hour|\/hour/gi;
            const regex2 = /based on experience/gi;
  
            let newJobs = data.map(job => {
                let newJob = {};
                let iframe = document.createElement("iframe");
                iframe.style.width = "0px";
                iframe.style.height = "0px";
                iframe.srcdoc = job.content.rendered;
                document.body.appendChild(iframe);
                iframe.onload = ()=>{
                    let jobData = {...iframe.contentWindow.workingpro_job, ...job.meta,  id: job.id};
                    jobData.coordinates = jobData.coordinates.split(" ");
                    jobData.coordinates = [parseFloat(jobData.coordinates[1]),parseFloat(jobData.coordinates[0])]
                 //   jobData.coordinates[0] = parseFloat(jobData.coordinates[0]).toFixed(2);
                  //  jobData.coordinates[1] = parseFloat(jobData.coordinates[1]).toFixed(2);
                    if (typeof jobData.compensation === "object") {
                        jobData.compensation[1] = jobData.compensation[1].replace(regex, "/hr");
                    } else {
                        jobData.compensation = jobData.compensation.replace(regex2, "Experience Based");
                    }
                    jobData.original = iframe.contentDocument.body.innerHTML;
                    console.log(jobData);
                    setJobs((jobsArray) => {return [...jobsArray, jobData]})
                    iframe.remove()
                }

                function returnJob(jobData){
                    return jobData;
                }
            })

            }).catch(e => console.error);
      //  }
    },[tradeFilter, locationFilter])

    let JobsDetailComponent = JobDetailModal;

    const tradeRegex = new RegExp(tradeFilter, "i");

    let filteredJobs = jobs.filter(job => job.location.includes(locationFilter));
     filteredJobs = filteredJobs.filter(job => (tradeRegex.test(job.title) || tradeRegex.test(job.description)));

     // Sort in ascending order (oldest to newest)
     if (sortBy === 'oldest') {
         filteredJobs = filteredJobs.slice().sort((a, b) => a.date - b.date);
     } else {
         // Sort in descending order (newest to oldest)
         filteredJobs = filteredJobs.slice().sort((a, b) => b.date - a.date);
        }


    return <Widget title={<Box style={{position:"relative"}} display={"inline-flex"} justifyContent={"space-between"}  alignItems={"center"} flexGrow="1" width={"calc(100% - 49px)"}>
        <Box flexGrow="1">Jobs
      <select style={{background:"#fff", color:"#32a0ff"}} onChange={e => {console.log(e.currentTarget.value); setLocationFilter(e.currentTarget.value)}}>
            <option value="">All Cities</option>
            <option value="newyork">New York</option>
            <option value="brooklyn">Brooklyn</option>
            <option value="queens">Queens</option>
            <option value="bronx">Bronx</option>
            <option value="staten">State Island</option>
        </select>
        <select style={{background:"#fff", color:"#32a0ff"}}  onChange={e => {console.log(e.currentTarget.value); setTradeFilter(e.currentTarget.value)}}>
            <option value="">All Trades</option>
            <option value="carpenter">Carpenter</option>
            <option value="painter">Painter</option>
            <option value="plumber">Plumber</option>
            <option value="mason">Mason</option>
            <option value="electrician">Electrician</option>
            <option value="hvac">HVAC</option>
        </select></Box>
        <JobsSearch setJobs={setJobs} jobs={jobs}/>
    <select style={{marginLeft:"24px", marginRight:"-16px",background:"#32a0ff", color:"#fff", width:"fit-content", borderRight:"10px solid rgb(50, 160, 255)"}} onChange={e => {console.log(e.currentTarget.value); setSortBy(e.currentTarget.value !== "newest" ? 'oldest' : 'newest')}}>
        <option value="newest">NEWEST</option>
        <option value="oldest">OLDEST</option>
    </select>
        </Box>} icon={<img src={hardhat_icon} style={{display:"inline-block",width:"35px",verticalAlign:"middle",marginRight:"10px"}}/>}>
    <JobsContainer padding={"24px"} fontSize={"14px"}>
        {filteredJobs.map(job => {

        

            return <Flex padding={"10px 0"} borderBottom={"1px solid #ccc"} alignItems={"center"} data-job-id={job.id}>
                <JobIcon trade={findJobTrade(job)}/>
                <Box paddingLeft={"8px"} flexGrow={1}>
                    <JobTitle onClick={()=>{
                            // Hack to show jobs details 

                            let jobDetailContainer = document.createElement("div");
                            jobDetailContainer.id = "job-details-modal-container";
                            document.body.appendChild(jobDetailContainer);
                            let jobDetailModalRoot = createRoot(jobDetailContainer);


                            jobDetailModalRoot.render(<JobsDetailComponent {...job} trade={findJobTrade(job)}></JobsDetailComponent>)

                        }}>{job.title}</JobTitle>
                    <JobSubtitle>
                        {job.employment_type+", "+job.location}
                    </JobSubtitle>
                </Box>
                <Flex flexDirection={"column"} justifyContent={"space-between"} height={"100%"} textAlign={"right"} minHeight={"40px"}>
                   
                        <JobCompensation>{typeof job.compensation === "object" ? job.compensation.join(" - ") : job.compensation}</JobCompensation>
                        <MoreDetails className="more-details" onClick={()=>{
                            // Hack to show jobs details 

                            let jobDetailContainer = document.createElement("div");
                            jobDetailContainer.id = "job-details-modal-container";
                            document.body.appendChild(jobDetailContainer);
                            let jobDetailModalRoot = createRoot(jobDetailContainer);


                            jobDetailModalRoot.render(<JobsDetailComponent {...job} trade={findJobTrade(job)}></JobsDetailComponent>)

                        }}>More details</MoreDetails>
              
                </Flex>
                <Button primary  style={{fontSize: "14px", width: "fit-content", padding: "0px 24px", lineHeight:"40px", marginLeft: "16px", alignSelf: "center", height: "auto"}}  onClick={() =>{
             window.open(job.url)
        }}>Apply <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{marginLeft:"8px"}}/></Button>
                
            </Flex>
        })}
       
        </JobsContainer>
</Widget>
    }

export default JobsWidget;