
import styled,{createGlobalStyle} from 'styled-components';
import * as React from 'react';
import {Box, Flex} from 'rebass';

import header_background from '../resources/images/wp_main.jpg';
import logo_symbol from '../resources/images/wp_logo.png';
import logo_text from '../resources/images/wp_logo_text.png';
import Button from './Button';
import UserMenu from './UserMenu';

const GlobalStyles = createGlobalStyle`

    #header ~ hr {display: none;}
    #youzify-wall-nav { display: none;}
    #block-10 .item-options{
        display: flex;
        justify-content: center;

        & a{
            margin: 0 4px;
            font-size: 12px;
        }
    }

    #block-8 .widget-title{
        font-family: inherit;
    }

    ul li.vcard{
        padding: 0 16px !important;

        .item-meta {
            font-size: 12px;
            margin: 8px 0 0;
        }
    }

    .youzify-link-item .svg-inline--fa .this{
        border-radius: 100%;
        fill: #fff;
        stroke: #fff;
        color: #fff;
        padding: 8px;
        vertical-align: middle;
        margin-right: 4px;
        
    }

    .youzify-my-account-widget .youzify-menu-icon-colorful svg{
        font-size: 14px;
        border-radius: 100px;
        fill: #fff;
        stroke: #fff;
        color: #fff;
        padding: 8px;
        vertical-align: middle;
        margin-right: 12px;
        line-height: 35px;
        // width: 35px;
        // height: 35px;
    }

    .youzify-my-account-widget .youzify-menu-icon-colorful .youzify-link-profile-settings svg {
        background-color: #9dd958
    }
    
    .youzify-my-account-widget .youzify-menu-icon-colorful .youzify-link-widgets-settings svg {
        background-color: #707dc3
    }
    
    .youzify-my-account-widget .youzify-menu-icon-colorful .youzify-link-inbox svg {
        background-color: #5ac6ed
    }
    
    .youzify-my-account-widget .youzify-menu-icon-colorful .youzify-link-notifications svg {
        background-color: #ffc107
    }
    
    .youzify-my-account-widget .youzify-menu-icon-colorful .youzify-link-friendship-requests svg {
        background-color: #fd4e96
    }
    
    .youzify-my-account-widget .youzify-menu-icon-colorful .youzify-link-account-settings svg {
        background-color: #24cbbb
    }

    .youzify-my-account-widget .youzify-menu-icon-colorful .youzify-link-logout svg {
        background-color: #9e9e9e
    }

    .youzify-load-emojis svg {
        font-size: 17px;
        color: #bbb;
    }

    .youzify #activity-stream .ac-form .youzify-load-emojis svg, .youzify-wall-comments-buttons svg{
        color: #b2b2b2;
        font-size: 18px;
    }

    .youzify-wall-options .youzify-wall-opts-item label svg{
        border-radius: 100%;
        fill: #fff;
        stroke: #fff;
        color: #9b9b9b;
        padding: 0;
        vertical-align: middle;
        margin-right: 10px;
        font-size: 14px;
    }

    svg.youzify-small-verified-icon{
        width: 17px;
    height: 17px;
    font-size: 10px;
    line-height: 16px;
    margin: 0 2px 0 5px;
    }

    .youzify .activity-list li.load-more a svg {
        margin-right: 8px;
        color: inherit;
        
    }

    .youzify-wall-new-post .youzify-wall-nothumb .youzify-thumbnail-icon i {
       line-height: 80px;
    }
`;

export const HeaderBackground = styled(Box)`
    background-image: url(${header_background});
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const LogoSymbol = styled(Box)`
position: relative;
background-image: url(${logo_symbol});
width: 45px;
height: 45px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
cursor: pointer;
`;

export const LogoText = styled(Box)`
position: relative;
background-image: url(${logo_text});
width: 160px;
height: 33px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
cursor: pointer;
&&& {
    margin-left: 16px;
}

@media screen and (max-width: 550px) {
  
}
`;

export const StyledHeader = styled(Flex)`
position: relative;
padding: 12px;
justify-content: space-between;
align-items: center;
top: 0px;
left: 0;
width: 100%;
height: auto;
z-index: 999;

@media screen and (min-width: 550px){
    padding: 16px;
}

& .tag-line {
    display: none;

    @media screen and (min-width: 640px) {
        display: block;
    }

}

@media screen and (max-width: 550px) {
    
    
    & .logo-symbol {
        width: 36px !important;
        height: 36px !important;
    }
    
    & .logo-text {
        width: 120px !important;
        margin-left: 8px !important;
    }
    
    & .beta-stamp {
        heigth: 23px !important;
    }
}


   

@media screen and (min-width: 768){
    padding: 24px 16px;
}


`;

export const BetaStamp = styled(Box)`
position: relative;
    color: #fff;
    text-align: right;
    font-size: 12px;
    height: 28px;

    &&& {
        margin-left: 8px;
    }

`;

export const Tagline = styled(Box)`
    
    position: relative;
    color: #fff;
    margin-left: 16px !important;

`;

const AnonymousMenu = styled(Flex)`
    position: relative;

    & .sign-up-button,& .log-in-button {
        font-size: 8px;
        font-weight: 500;
        min-height: 24px;
        padding: 8px 16px;
        line-height: 1;
        width: fit-content;
        font-family: inherit;
        height: auto;
        box-shadow: none;
        cursor: pointer;

        @media screen and (min-width: 550px){
            font-size: 12px;
        }
    
    }

  
    & .log-in-button{
        color: #fff;
        border-color: #fff;
        border-width: 1px;
        margin-right: 8px;
        @media screen and (min-width: 550px){
           &&&{
               border-width: 2px;
           }
        }
    }
`;


const Header = props => {
  //  const navigate = useNavigate();




    return (<StyledHeader>
           <GlobalStyles/>
            <HeaderBackground />
            <Flex alignItems={"center"} flexWrap={"wrap"}>
            <LogoSymbol className='logo-symbol' onClick={()=>{
           //     navigate("/")
           window.location = "/";
            }}/>
             <LogoText className='logo-text' onClick={()=>{
           //     navigate("/")
           window.location = "/";
            }}/>
             <BetaStamp className='beta-stamp'>Beta</BetaStamp>
             <Tagline className='tag-line'>Networking for construction Pros</Tagline>
             </Flex>
             {document.querySelector("#wp-admin-bar-my-account") ? <UserMenu/>
             : <AnonymousMenu>
             <Button knockedOut className="log-in-button" onClick={()=>{
                window.location = "https://workingpro.co/login";
             }}>LOG-IN</Button>
             <Button primary className="sign-up-button" onClick={()=>{
                window.location = "https://workingpro.co/register";
             }}>SIGN-UP</Button>
             </AnonymousMenu>
             }

        
        </StyledHeader>)
}

export default Header;