import * as React from 'react';
import styled from 'styled-components';
import {Box, Flex} from 'rebass';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPeopleGroup, faUserGroup, faBullhorn, faUsersRectangle, faHeart, faBook} from '@fortawesome/free-solid-svg-icons';

const MainNavContainer = styled(Flex)`
align-items: center;
justify-content: space-between;
padding: 15px 20px;
    margin: 16px auto !important;

	@media screen and (max-width: 1169px){
		margin: 16px !important;
		width: auto;
	}
    background-color: #fff;
    border-radius: 3px;
	width: 100%;
	max-width: 1170px;

& .home-link .nav-icon:before{content: "\f015";}

& .tab-link:nth-child(2) .nav-icon{
	background: #32a0ff;
    background: url(../images/dotted-bg.png),linear-gradient(to left,#24c6dc ,#514a9d);
    background: url(../images/dotted-bg.png),-webkit-linear-gradient(right,#24c6dc ,#514a9d);
}

& .tab-link:nth-child(3) .nav-icon{
	background: #ffca6e;
    background: url(../images/dotted-bg.png),linear-gradient(to left,#f9d423 ,#ff4e50);
    background: url(../images/dotted-bg.png),-webkit-linear-gradient(right,#f9d423 ,#ff4e50);
}

& .tab-link:nth-child(4) .nav-icon{
	background: #c154fe;
    background: url(../images/dotted-bg.png),linear-gradient(to left,#c154fe ,#673ab7);
    background: url(../images/dotted-bg.png),-webkit-linear-gradient(right,#c154fe ,#673ab7);
}

& .tab-link:nth-child(5) .nav-icon{
	background: #ff7376;
    background: url(../images/dotted-bg.png),linear-gradient(to left,#fa6d9d ,#ff1e6b);
    background: url(../images/dotted-bg.png),-webkit-linear-gradient(right,#fa6d9d ,#ff1e6b);
}

& .tab-link:nth-child(6) .nav-icon{
	background: #24cbbb;
    background: url(../images/dotted-bg.png),linear-gradient(to left,#45eada ,#009688);
    background: url(../images/dotted-bg.png),-webkit-linear-gradient(right,#45eada ,#009688);
}


`;

const MainNavTabGroup = styled(Flex)`
	align-items: center;
	flex-grow:1;
	justify-content: center;
`;
const MainNavTab = styled(Box)`
margin: 5px 0;
color: #969696;
font-size: 12px;
cursor: pointer;
font-weight: 600;
margin-right: 15px !important;

text-transform: initial;

& a{color: inherit;}

@media screen and (min-width: 768px) {
	font-size: 14px;
}
`;
const MainNavIcon = styled.span`
	display: block;
	color: #fff;
	width: 32px; 
	height: 32px;
	font-size: 14px;
	line-height: 32px;
	text-align: center;
	border-radius: 100%;
	background: linear-gradient(99deg, #525252 12%, rgb(0 0 0) 74%);
    font-family: "Font Awesome 5 Free"!important;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	margin: 0 auto 8px;
    font-size: 14px;
	vertical-align: middle;
	
	@media screen and (min-width: 684px) {
		display: inline-flex;
		align-items: center; 
		justify-content: center;
		margin-right: 8px;
	}
`;

const MainNav = props => {

	let username = document.querySelector("#header .username .handle") ? document.querySelector("#header .username .handle").textContent : "";

    return (<MainNavContainer>
				<MainNavTabGroup className="" aria-label="Sitewide activities navigation" role="navigation">
					<MainNavTab className="tab-link home-link"><a href="https://workingpro.co"><MainNavIcon className="nav-icon"/>Home</a>
					</MainNavTab>
					<MainNavTab className="tab-link"><a href="https://workingpro.co/articles/">
						<MainNavIcon className="nav-icon">
						<FontAwesomeIcon icon={faBook}/>
							</MainNavIcon>
							Articles</a>
					</MainNavTab>
					<MainNavTab className="tab-link"><a href="https://workingpro.co/members/">
						<MainNavIcon className="nav-icon">
						<FontAwesomeIcon icon={faPeopleGroup}/>
							</MainNavIcon>
							Members</a>
					</MainNavTab>
					<MainNavTab className="tab-link"><a href="https://workingpro.co/groups/"><MainNavIcon className="nav-icon"><FontAwesomeIcon icon={faUsersRectangle}/></MainNavIcon>Groups</a>
					</MainNavTab>
					{document.querySelector("#wp-admin-bar-my-account") && <><MainNavTab className="tab-link"><a href={`https://workingpro.co/members/${username}/friends/`}>
						<MainNavIcon className="nav-icon">
						<FontAwesomeIcon icon={faUserGroup}/>
							
						</MainNavIcon>
						Friends</a>
					</MainNavTab>
			
					<MainNavTab className="tab-link"><a href={`https://workingpro.co/members/${username}/activity/favorites`}><MainNavIcon className="nav-icon"> <FontAwesomeIcon icon={faHeart}/></MainNavIcon>Favorites</a>
					</MainNavTab>
					<MainNavTab className="tab-link"><a href={`https://workingpro.co/members/${username}/activity/mentions`}><MainNavIcon className="nav-icon"><FontAwesomeIcon icon={faBullhorn}/></MainNavIcon>Mentions</a>
					</MainNavTab>
					</>
					}
				</MainNavTabGroup>
		</MainNavContainer>)
}
export default MainNav;