import * as React from 'react';
import {Flex, Box} from 'rebass';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsersRectangle, faUserGroup, faInbox, faUserPlus, faDoorOpen, faGears } from '@fortawesome/free-solid-svg-icons';

const UserBubble = styled(Box)`
    position: relative;
    border-radius: 100%;
    border: 2px solid #fff;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1);
    width: 45px;
    height: 45px;
    background-size: contain;
`;
const Menu = styled(Box)`
    display: none;
    position: absolute;
    top: 70px;
    font-weight:600;
    width: auto;
    background: #808080;
    color:#fff;
    padding: 8px 16px 8px 8px;
    border-radius: 2px;
    text-align: right;
    max-width: 300px;
    min-width: 120px;
    right: 0;
    left: auto;
    box-shadow: 0 2px 1px 0 rgb(164 166 167 / 33%);

    .open & {
        display: block;
    }
`;

const MenuItem = styled(Box)`
cursor:pointer; font-size: 14px; position: relative; box-sizing: content-box; padding-left: 40px;
&&&{margin: 8px 0;}
`;

const UserInfo = styled(Box)`
position: relative;
    color: #fff;
    font-size: 12px;
    text-align: right;
    padding-right: 16px;

    & .full-name {font-weight: bold;}
    & .username {}
`;

const Icon = styled(Box)`
display: inline-flex;
align-items: center;
justify-content: center;
position: absolute;
left: 0;
top: 50%;
transform: translateY(-50%);
background: #666;
border-radius: 100%;
width: 24px;
height: 24px;
font-size:10px;


`;

const UserMenu = props =>{

    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(()=>{
        if (document.querySelector(".avatar.photo.lazyloaded")) {

            let userImage = document.querySelector(".avatar.photo.lazyloaded");
            let userBubble = document.querySelector("#user-bubble");
            userBubble.style.backgroundImage = `url('${userImage?.src}')`;

            document.querySelector("#wpadminbar").style.display = "none";
            document.querySelector("html").style.marginTop = "0 !important";
        }
    },[]);


    let adminBar = document.querySelector("#wp-admin-bar-user-info");
    let displayName = "Display Name";
    let userName = "username";
    if (adminBar) {
        displayName = adminBar.querySelector(".display-name").textContent;
        userName = adminBar.querySelector(".username").textContent;
    }

return(<Flex margin={"0 0 0 auto"} position={"relative"} className={isOpen ? "open" : ''} alignItems={"center"}>
    <UserInfo>
        <Box className='full-name'>{displayName}</Box>
        <Box className='username'><span>@</span><span className="handle">{userName}</span></Box>
    </UserInfo>
    <UserBubble id="user-bubble" onClick={()=>{setIsOpen(!isOpen)}}></UserBubble>
    <Menu>

    <MenuItem onClick={() => {
        window.location = "https://workingpro.co/members/"+userName;
    }}><Icon><FontAwesomeIcon icon={faUser} /></Icon>Profile</MenuItem>
    <MenuItem onClick={()=>{
        let url = document.querySelector("#wp-admin-bar-my-account-messages a").getAttribute("href");
        window.location = url;
    }}><Icon><FontAwesomeIcon icon={faInbox} /></Icon>Messages</MenuItem>
    <MenuItem onClick={()=>{
        let url = document.querySelector("#wp-admin-bar-my-account-groups a").getAttribute("href");
        window.location = url;
    }}><Icon><FontAwesomeIcon icon={faUsersRectangle} /></Icon>Groups</MenuItem>
    <MenuItem onClick={()=>{
        let url = document.querySelector("#wp-admin-bar-my-account-friends a").getAttribute("href");
        window.location = url;
    }}><Icon><FontAwesomeIcon icon={faUserGroup} /></Icon>Friends</MenuItem>
    <MenuItem onClick={()=>{
        let url = document.querySelector("#wp-admin-bar-my-account-buddypress-invitations a").getAttribute("href");
        window.location = url;
    }}><Icon><FontAwesomeIcon icon={faUserPlus} /></Icon>Invitations</MenuItem>
    <MenuItem onClick={()=>{
        let url = document.querySelector("#wp-admin-bar-my-account-settings a").getAttribute("href");
        window.location = url;
    }}><Icon><FontAwesomeIcon icon={faGears} /></Icon>Settings</MenuItem>
    <MenuItem  onClick={() => {
        window.location = "https://workingpro.co/wp-login.php?action=logout&_wpnonce=6a6e72fc3a";
    }}><Icon><FontAwesomeIcon icon={faDoorOpen} /></Icon>Log-out</MenuItem>
    </Menu>
</Flex>)
}

export default UserMenu;