import * as React from 'react';
import Widget from '../components/Widget';
import {Box, Flex} from 'rebass';
import styled from 'styled-components';

import calculateRelativeDate from './calculateRelativeDate';

import hardhat_icon from '../resources/images/hardhat_icon.png';
import electrician_icon from '../resources/images/electrician_icon.png';
import plumber_icon from '../resources/images/plumber_icon.png';
import carpenter_icon from '../resources/images/carpenter_icon.png';
import hvac_icon from '../resources/images/hvac_icon.png';

import Map from '../hiringhall/views/Map';
import OpenStreetMap from '../hiringhall/components/OpenStreetMap';

import { JobIcon } from './JobsList';
import Button from '../components/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';

import craigslistLogo from '../resources/images/craigslist_logo.png'

export const ModalHeader = styled(Box)`
    background: #000;
    padding: 16px;
`;

export const ModalContent = styled(Box)`
    padding: 32px 16px 32px;
    padding-top: 0;
`;

export const StyledWelcomeModal = styled(Box)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;

    max-height: 90%;
   
    background-size: cover;
    border-radius: 10px;
    overflow: auto;
    box-shadow: 6px 8px 8px -4px rgb(0 0 0 / 80%);
    z-index: 999;
    font-weight: 600;
    font-size: 14px;
    max-width: 90%;
    width: 100%;

    @media screen and (min-width: 600px) {
        width: fit-content;
        max-width: 1000px;
    }
   
`

export const ModalOverlay = styled(Box)`

    display:block;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.75);
    top: 0;
    left: 0;
    z-index: 9;

`

export function JobDetailModal(props){
    let modalRef = React.useRef();

    React.useEffect(() => {
        if (modalRef.current) {
            // modalRef.current.addEventListener("click", (e) => {
            //     window.localStorage.setItem("workingpro-user", JSON.stringify({onboarding: false, welcome: true, loggedIn: false}));
            //     e.currentTarget.remove();
            // })
        }
    })
    return <Box ref={modalRef}><ModalOverlay onClick={(e) => {
        e.currentTarget.parentNode.parentNode.remove();
    }}/>
    <StyledWelcomeModal>
       
        <ModalContent>
          <JobDetail {...props}/>
        </ModalContent>
    </StyledWelcomeModal>
    </Box>
}

const icons = {
    'electrician': electrician_icon,
    'plumber': plumber_icon,
    'carpenter': carpenter_icon,
    'hvac': hvac_icon
}

const SeeAllButon = styled(Box)`

`;

const JobsContainer = styled(Box)`
    max-height: 400px;
    overflow: scroll;
`;


const Title = styled(Box)`
    color: rgb(90, 198, 237);
    font-size: 16px;
`;

const StyledWidget = styled(Widget)`
padding-top: 0px;

 & .youzify-widget-title {
    padding: 16px 0 !important;
    display: flex;
    align-items: center;
 }
`

const JobDetail = (props) => {
    let descriptionHtml = props.original.replace(/\n/g, "</br>");
    return <StyledWidget title={<>{"JOBS"}<Box display={"inline-block"} marginLeft={"16px"} color={"rgb(50, 160, 255)"} style={{textTransform:"capitalize"}}>{`> ${props.location.split(",")[1]} > ${props.location.split(",")[0]}`}</Box><Box style={{fontSize: "12px", margin:"0 0 0 auto"}}> Source: <img style={{width:"55px", verticalAlign:"middle"}} src={craigslistLogo}/></Box></>} icon={<img src={hardhat_icon} style={{display:"inline-block",width:"35px",verticalAlign:"middle",marginRight:"10px"}}/>} style={{marginBottom:"0", paddingTop:"8px"}}>
   <Box padding={"16px 16px 16px 76px"}> 
   <JobIcon trade={props.trade} style={{position:"absolute", left: '36px'}}/>
   
   <Title>{props.title}</Title>
   <Box>{calculateRelativeDate(props.job_date)} • {props.employment_type} • {props.location.replace(/,/g," • ")}</Box>
   <Box paddingTop={"16px"}>
   <Box style={{float:"right", position:"relative"}} width={200} height={200} backgroundColor={"red"} margin={"0 0 16px 16px"}>
    {/* <Map jobs={[
        {
            lat:props.coordinates.split(" ")[0],
        lng:props.coordinates.split(" ")[1]
        }]} detail={true}/> */}
        <OpenStreetMap position={props.coordinates}/>
   </Box>
  <Box>
    {props.description}
  </Box>
   </Box>
 
   <Button style={{color:"#000", background:"rgb(253, 217, 0)", fontFamily: "sans-serif", maxWidth:"150px", display:"block", margin: "0 auto", lineHeight: "2", margin:"32px auto 0", fontSize:"16px"}} onClick={() => {
    window.open(props.url)
}}>Apply <FontAwesomeIcon icon={faArrowUpRightFromSquare}  style={{marginLeft:"8px"}}/></Button>
<Flex justifyContent={"center"} alignItems={"center"} marginTop={"16px"}>
    <Box  onClick={(e)=>{
        let thisJob = document.querySelector(`#job-list [data-job-id='${props.id}']`);
        let prevJob = thisJob.previousElementSibling;
        if (prevJob) {
            document.querySelector("#job-details-modal-container").remove();
            prevJob.querySelector(".more-details").click();
        }
    }}>{`< Prev`}</Box>
    <Box marginLeft={"16px"} onClick={(e)=>{
        let thisJob = document.querySelector(`#job-list [data-job-id='${props.id}']`);
        let nextJob = thisJob.nextElementSibling;
        if (nextJob) {
            document.querySelector("#job-details-modal-container").remove();
            nextJob.querySelector(".more-details").click();
        }
    }}>{`Next >`}</Box>
</Flex>
</Box>
</StyledWidget>
    }

export default JobDetail;