import * as React from 'react';
import styled
 from 'styled-components';

const StyledWidget = styled.div`
position: relative; 
&&& *{
    font-family: 'Open Sans';
}
    background: #fff;
    margin-bottom: 35px;

    & .youzify-widget-main-content {
        width: 100%;
        z-index: 9999;
        color: #8d8c8c;
    }

    & .youzify-widget-head{
        border-bottom: 1px solid #f2f2f2;
    }

    & .youzify-widget-title{
        margin: 0;
    color: #858f94;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 15px 35px;
    letter-spacing: initial;
    }
`;

const Widget = props =>{
return(<StyledWidget className={`youzify-widget youzify-about_me youzify-white-bg youzify-wg-title-icon-bg fadeIn full-visible ${props.className}`} data-effect="fadeIn" style={{visibility:"visible", ...props.style}} >

    <div className="youzify-widget-main-content">

                        <div className="youzify-widget-head">
            <h2 className="youzify-widget-title">
                                            {props.icon || <i className="fas fa-user"></i>}												{props.title}					</h2>
                                
                            </div>
        
        <div className="youzify-widget-content">
        {props.children}   
</div>

    </div>

</StyledWidget>)
}

export default Widget;