import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import styled from 'styled-components';

const StyledMap = styled.div`
& .leaflet-control-attribution {
    display: none;
}
`;

const OpenStreetMap = ({position}) => {
  
    console.log(position);


  return (
    <StyledMap>
    
      <MapContainer center={position} zoom={13} style={{ height: '200px', width: '200px' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution=''
        />
        <Circle
  center={position}
  radius={500}
  fillColor="blue" // Fill color of the circle
  fillOpacity={0.4} // Fill opacity (0.0 to 1.0)
  color="blue" // Border color
/>
      </MapContainer>
    </StyledMap>
  );
};

export default OpenStreetMap;
