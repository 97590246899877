
import React from 'react';
import CarpenterIcon from '../icons/carpenter.svg';
import MasonIcon from '../icons/mason.svg';
import ElectricianIcon from '../icons/electrician.svg';
import PlumberIcon from '../icons/plumber.svg';
import PainterIcon from '../icons/painter.svg';
import BoilmakerIcon from '../icons/boilmaker.svg';
import FencebuilderIcon from '../icons/fence_builder.svg';
import FloorLayerIcon from '../icons/floor_layer.svg';
import StoneMasonIcon from '../icons/mason.svg';
import SteelworkerIcon from '../icons/iron_steel_worker.svg';
import PipefitterIcon from '../icons/septic_sewag.svg';
import CraneoperatorIcon from '../icons/septic_sewag.svg';
import ConstructionWorker from '../icons/construction_worker.svg';
import TaperIcon from '../icons/taper.svg';
import DrillerIcon from '../icons/driller.svg';

import styled from 'styled-components';



const StyledLocator = styled.div`
    width:48px;
    transform: translate(-50%,-50%);
    width:fit-content; 
    &:hover, &.toggled {width:fit-content; }

    *{transition:all .25s ease-in-out;}
`;

const LocatorIcon = styled.div`
    position:relative; z-index:1;
    width:36px;
    height:36px;
    background:#006d8f;
    border:3px solid #ffc600;
    border-radius:50%;
    margin:0 auto;
    display:flex;
    align-items:center;
    align-content:center;
    flex-direction:column;
    justify-content:center;
    overflow:hidden;

    & img{
        width:100%;
        filter: saturate(0) brightness(25);
        transform:scale(1.05);
    }

    :hover &, .toggled &{
        border-color:#006d8f; background:#ffc600; stroke:#000; width:36px; height:36px; 
        border-color:black;
      
      }
`;

const LocatorLabel = styled.div`

    position:relative;
   
   font-size:9px; font-weight:600; background:#ffc600; border-radius:4px; text-align:center; width:fit-content;     height: 2em;
  padding: 4px;
  top: -4px;
  box-sizing: border-box;
  margin:0 auto;
  

  & span{display:none;}

  &::after{
    content: "";
    display: block;
    box-shadow: 0 7px 3px -2px rgba(119,119,119,1);
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: 0;
    left: 0;
    }

    :hover &, .toggled &{
        border-radius:0px;
          background:#006d8f;
          background:#000;
          color:#fff;
          font-size:12.6px;
          padding:8px;
          padding: 4px 12px;
          box-sizing: content-box;
          display: flex;
          align-items: center;
          font-weight:400px;
  
        &  span{display:inline; white-space: nowrap; margin-right:4px; transition:none;}
      }
`;

export default function Locator(props){

    let locatorImage = props.job.title == "Carpenter" ? CarpenterIcon : props.job.title == "Electrician" ? ElectricianIcon : props.job.title == "Mason" ? MasonIcon : props.job.title == "Stone Mason" ? MasonIcon : props.job.title == "Plumber" ? PlumberIcon : props.job.title == "Painter" ? PainterIcon : props.job.title == "Pipefitter" ? PipefitterIcon : props.job.title == "Steelworker" ? SteelworkerIcon : props.job.title == "Crane Operator" ? CraneoperatorIcon : props.job.title == "Boilmaker" ? BoilmakerIcon : props.job.title == "Driller" ? DrillerIcon : props.job.title == "Taper" ? TaperIcon : ConstructionWorker;

    let backgroundColor = props.job.title == "Mason" ? "#4d4888" : props.job.title == "Electrician" ? "#8e1e09" : '';
  
    return <StyledLocator lat={props.job.lat} lng={props.job.lng} style={props.style} onClick={props.onClick} style={{zIndex:"9"}}>
        
        <LocatorIcon className="marker-icon" style={{backgroundColor:backgroundColor}}><img src={locatorImage}/></LocatorIcon>
        
        <LocatorLabel className="marker-label"><span> {props.job.company} </span>{" $"+props.job.rate+"/h"}</LocatorLabel>
        </StyledLocator>;
}