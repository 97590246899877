import React, { useEffect, useState, useRef } from 'react';
import {createRoot} from 'react-dom/client';
//import {BrowserRouter as Router, Route, NavLink} from 'react-router-dom';
import {Flex, Box} from 'rebass';
import styled from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import Confirm from './components/Confirm';
import Button from './components/Button';
import MainNav from './components/MainNav';
import Header from './components/Header';
import Footer from './components/Footer';
import WelcomeModal from './components/WelcomeModal';
import JobsWidget from './views/JobsWidget'
import TemplateTxt from "../public/template-job-list.php";
import HeaderTxt from "../public/header.php";

//import fontawesomeCss from '@fortawesome/fontawesome-free/css/all.min.css';
// import fontawesomeCss from '@fortawesome/fontawesome-free/css/v5-font-face.min.css';

// import {
//     Chart,
//     ArcElement,
//     LineElement,
//     BarElement,
//     PointElement,
//     BarController,
//     BubbleController,
//     DoughnutController,
//     LineController,
//     PieController,
//     PolarAreaController,
//     RadarController,
//     ScatterController,
//     CategoryScale,
//     LinearScale,
//     LogarithmicScale,
//     RadialLinearScale,
//     TimeScale,
//     TimeSeriesScale,
//     Decimation,
//     Filler,
//     Legend,
//     Title,
//     Tooltip,
//     SubTitle
//   } from 'chart.js';
  
//   Chart.register(
//     ArcElement,
//     LineElement,
//     BarElement,
//     PointElement,
//     BarController,
//     BubbleController,
//     DoughnutController,
//     LineController,
//     PieController,
//     PolarAreaController,
//     RadarController,
//     ScatterController,
//     CategoryScale,
//     LinearScale,
//     LogarithmicScale,
//     RadialLinearScale,
//     TimeScale,
//     TimeSeriesScale,
//     Decimation,
//     Filler,
//     Legend,
//     Title,
//     Tooltip,
//     SubTitle
//   );

import pkg from '../package.json';


const EndorseBanner = styled(Box)`
    padding: 16px;
    background-color: var(--dark-yellow);
    color: #fff;
    font-weight: bold;
    font-size: 12px;
`;

const JobEndorsement = styled(Box)`
text-align: left;
margin: 8px 0;
border-top: 1px solid #ccc;
padding: 16px 0;
`;

const JobTitle = styled(Box)`
    font-size: 18px;
    font-weight: bold;
    color: #858f92;

`;
const UserPhoto = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    background: #666;
`;
const UserAction = styled(Box)`
font-size: 12px;
font-weight: bold;
color: #858f92;

`;
const EndorseIcon = styled(Box)`
width: 35px; 
height: 35px; 
background: #ccc; border-radius: 50%;`;
const EndorseNumber = styled(Box)`
    font-weight: bold;
    color: var(--light-blue);
    font-size: 12px;
`;
const YellowButton = styled(Box)`

    background: var(--light-yellow);
    padding: 16px;
    text-align: center;
    line-height: 1;
    color: #fff;
    border-radius: 20px;
    font-weight: bold;
    font-size: 12px;
`;

const ConnectLabel = styled(Box)``;


const Widgets = props => {

    const [jobs, setJobs] = useState([]);
    const [users, setUsers] = useState([]);

    const [endorseModalOpen, setEndorseModalOpen] = useState(false);

    useEffect(() => {

        fetch("https://workingpro.co/wp-json/wp/v2/job").then(r => r.json()).then(data => {
            console.log(data);

            let newJobs = data.map((job, index) => {
                return {
                    job_id : index,
                    type : row[0],
                    date : row[1],
                    title : row[2],
                    level : row[3],
                    rate : row[4],
                    location : row[5],
                    lat : latitude,
                    lng : longitude,
                    description : row[8],
                    company : row[9],
                    rating : row[10],
                    distance : row[11],
                    logo : row[12]
                }
            })
        }).catch(e => console.error);

        let v4EndPoint = "https://sheets.googleapis.com/v4/spreadsheets/1_rBgk25LqqcofswYB6p_52cfD6NFargu-Dbok4r7MWI/values/Sheet1?key=AIzaSyAqXfr9Ibvt1SMD478sCZcbvgsL4KRXViw";
        fetch(v4EndPoint).then(result =>{return result.json()}).then(json =>{
    
        let newJobs = json.values.slice(1,20).map((row, index) =>{
          let latitude = parseFloat(row[6]);
          let longitude = parseFloat(row[7]);
         
          return {
            job_id : index,
            type : row[0],
            date : row[1],
            title : row[2],
            level : row[3],
            rate : row[4],
            location : row[5],
            lat : latitude,
            lng : longitude,
            description : row[8],
            company : row[9],
            rating : row[10],
            distance : row[11],
            logo : row[12]
          }
        }); 

        setJobs(newJobs);

    });

    if(users.length === 0){
        
        let v4EndPoint = "https://sheets.googleapis.com/v4/spreadsheets/1v4_e6rdmVmjR4gQd6Oa4s3813od8KeTNelx69tqYlHE/values/Sheet1?key=AIzaSyAqXfr9Ibvt1SMD478sCZcbvgsL4KRXViw";
        fetch(v4EndPoint).then(result =>{return result.json()}).then(json =>{
        
        
         // let rows = _.chunk(json.feed.entry, 21);
          //rows = rows.slice(1,51);
      
          let newUsers = json.values.slice(1).map((row, index) =>{
       
            return {
              user_id : index,
              type : row[0],
              first_name : row[1],
              last_name : row[2],
              username : row[3],
              email : row[4],
              phone : row[5],
              title : row[6],
              level : row[7],
              membership : row[8],
              union_id : row[9],
              location : row[10],
              work_area : row[11],
              image : "/images/user/"+row[3]+".jpeg",
              rate : row[13],
              rating : row[14],
              licenses : row[15],
              certificates : row[16],
              experience : row[17],
              about : row[18],
              lat : row[19],
              lng : row[20],
            }
          });
    
          setUsers(newUsers);
      
  
          });
        }

        const labels = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
          ];
        
          const data = {
            labels: labels,
            datasets: [{
              label: 'My First dataset',
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor: 'rgb(255, 99, 132)',
              data: [0, 10, 5, 2, 20, 30, 45],
            }]
          };
        
          const config = {
            type: 'line',
            data: data,
            options: {}
          };

          const myChart = new Chart(
            document.getElementById('user-points-chart'),
            config
          );

    },[]);

    // Move login widget to top on mobile

    if (window.innerWidth < 600) {
        if (document.body.classList.contains("not-logged-in")) {
            let loginWidget = document.querySelector(".youzify-membership-login-widget");
            let mobileNav = document.querySelector(".youzify-mobile-nav");
            console.log(mobileNav);
            console.log(loginWidget);
            mobileNav.insertAdjacentElement("afterend", loginWidget);
        }
    }


    const jobEndorsements = [];
    const userAwards = [];

    if(users.length > 0){

 
    for(let i=0; i<3; i++){
        jobEndorsements.push(<JobEndorsement>
                       
            <JobTitle>{users[i].title}</JobTitle>
            <Flex padding={"8px 0"} alignItems={"center"}>
                <UserPhoto src={"https://app.hiringhall.co/"+users[i].image}></UserPhoto>
                <UserAction padding={"8px"}>Endorsed by {users[i].first_name+" "+users[i].last_name}</UserAction>
            </Flex>
            <Flex>
                <EndorseIcon margin={"0 6px"}></EndorseIcon>
                <EndorseNumber padding={"8px"}>{parseInt(25*Math.random())} endorsements</EndorseNumber>
            </Flex>
           </JobEndorsement>)
    }
    for(let i = 0; i<8; i++){
        userAwards.push(<Flex padding={"8px"} alignItems={"center"}>
                            <UserPhoto src={"https://app.hiringhall.co/"+users[i].image}></UserPhoto>
                            <EndorseNumber padding={"8px"} fontWeight={"400"}>Endorsed by {users[i].first_name+" "+users[i].last_name}</EndorseNumber>
                        </Flex>) 
    }
}

    

 

    return (<><style>
        {`
        html {margin-top: 0 !important;}
        body{

            --light-yellow: #f9dd3a;
            --dark-yellow: #ffbf0a;
            --light-blue: #1ab8ea;
        }

        .youzify-default-content {
            padding: 16px;
        }
        `}
        </style>
        
        <div className="youzify-widget youzify-about_me youzify-white-bg youzify-wg-title-icon-bg fadeIn full-visible" data-effect="fadeIn" style={{textAlign:"center"}}>
        <ModalProvider>
            <Confirm key={Math.random()} isOpen={endorseModalOpen} title="Endorse Joe for Carpenting" confirmLabel="ENDORSE">
                <textarea></textarea>
            </Confirm>
        </ModalProvider>
    <div className="youzify-widget-main-content">

                        <div className="youzify-widget-head">
            <h2 className="youzify-widget-title">
                                            <i className="fas fa-user"></i>												Profile					</h2>
                                <a href="https://workingpro.hiringhall.co/wp/members/peterlee01/widgets/about_me"><i className="far fa-edit youzify-edit-widget"></i></a>
                            </div>
        
        <div className="youzify-widget-content">
                                
<div className="youzify-aboutme-content youzify-default-content">

                <div className="youzify-user-img youzify-photo-circle">
        <img loading="lazy" className=" ls-is-cached lazyloaded" data-src="https://workingpro.hiringhall.co/wp/wp-content/uploads/youzify/members/1/2022/04/download-150x150.jpg" alt="" src="https://workingpro.hiringhall.co/wp/wp-content/uploads/youzify/members/1/2022/04/download-150x150.jpg" style={{margin: "0 auto"}}/></div>
    
    <div className="youzify-aboutme-container">

                        <div className="youzify-aboutme-head">
            <h2 className="youzify-aboutme-name" style={{textAlign:"center"}}>Pedro Antonio Lee</h2>
           
        </div>
        
        
                       <EndorseBanner>Earn 10 job applications if you endorse Joe</EndorseBanner>
                       {jobEndorsements}
                       <JobEndorsement>
                       
                        <JobTitle>Carpenter</JobTitle>
                        <Flex padding={"8px 0"} alignItems={"center"}>
                            <UserPhoto></UserPhoto>
                            <UserAction padding={"8px"}>Endorsed by John Smith</UserAction>
                        </Flex>
                        <Flex>
                            <EndorseIcon margin={"0 6px"}></EndorseIcon>
                            <EndorseNumber padding={"8px"}>25 endorsements</EndorseNumber>
                        </Flex>
                       </JobEndorsement>

                       <JobEndorsement>
                        <JobTitle>Plumbing</JobTitle>
                        <Flex padding={"8px 0"} alignItems={"center"}>
                            <UserPhoto></UserPhoto>
                            <UserAction padding={"8px"}>Endorsed by John Smith</UserAction>
                        </Flex>
                        <Flex>
                            <EndorseIcon margin={"0 6px"}></EndorseIcon>
                            <EndorseNumber padding={"8px"}>25 endorsements</EndorseNumber>
                        </Flex>
                       </JobEndorsement>

                       <JobEndorsement>
                        <JobTitle>Foreman</JobTitle>
                        <Flex padding={"8px 0"} alignItems={"center"}>
                            <UserPhoto></UserPhoto>
                            <UserAction padding={"8px"}>Endorsed by John Smith</UserAction>
                        </Flex>
                        <Flex>
                            <EndorseIcon margin={"0 6px"}></EndorseIcon>
                            <EndorseNumber padding={"8px"}>25 endorsements</EndorseNumber>
                        </Flex>
                       </JobEndorsement>
                       <Button primary marginTop={"16px"} onClick={() => {
                        setEndorseModalOpen(true);
                       }}>Endorse Now</Button>


                     
        
    </div>

</div>

                                    </div>

    </div>

</div>



<div className="youzify-widget youzify-about_me youzify_effect youzify-white-bg youzify-wg-title-icon-bg fadeIn full-visible" data-effect="fadeIn">

    <div className="youzify-widget-main-content">

                        <div className="youzify-widget-head">
            <h2 className="youzify-widget-title">
                                            <i className="fas fa-user"></i>												My Stats					</h2>
                                <a href="https://workingpro.hiringhall.co/wp/members/peterlee01/widgets/about_me"><i className="far fa-edit youzify-edit-widget"></i></a>
                            </div>
        
        <div className="youzify-widget-content">
                                
<div className="youzify-aboutme-content youzify-default-content">

         
    
    <div className="youzify-aboutme-container">

        <Flex><canvas id="user-points-chart"/></Flex>

                      <Flex style={{borderBottom: "1px solid #ccc"}} padding={"0 0 16px"} fontWeight={"bold"}>
                        <Box style={{borderRight:"1px solid #ccc"}} flexGrow={1} textAlign={"center"}>
                            <Box>My Points</Box>
                            <Box fontSize={"1.25em"} paddingTop={"12px"}>120 pts</Box>
                        </Box>
                        <Box opacity={"0.5"} flexGrow={1} textAlign={"center"}>
                            <Box>Average Pro</Box>
                            <Box fontSize={"1.25em"} paddingTop={"12px"}>250 pts</Box>
                        </Box>
                      </Flex>

                      <UserAction padding={"8px"}>Connect to increase community score</UserAction>
                      {userAwards}
                      <Flex padding={"8px"} alignItems={"center"}>
                            <UserPhoto></UserPhoto>
                            <EndorseNumber padding={"8px"} fontWeight={"400"}>Endorsed by John Smith</EndorseNumber>
                        </Flex>
                        <Flex padding={"8px"} alignItems={"center"}>
                            <UserPhoto></UserPhoto>
                            <EndorseNumber padding={"8px"} fontWeight={"400"}>Endorsed by John Smith</EndorseNumber>
                        </Flex>
        
                        <Flex padding={"8px"} alignItems={"center"}>
                            <UserPhoto></UserPhoto>
                            <EndorseNumber padding={"8px"} fontWeight={"400"}>Endorsed by John Smith</EndorseNumber>
                        </Flex>
        
                        <Flex padding={"8px"} alignItems={"center"}>
                            <UserPhoto></UserPhoto>
                            <EndorseNumber padding={"8px"} fontWeight={"400"}>Endorsed by John Smith</EndorseNumber>
                        </Flex>
        
                        <Flex padding={"8px"} alignItems={"center"}>
                            <UserPhoto></UserPhoto>
                            <EndorseNumber padding={"8px"} fontWeight={"400"}>Endorsed by John Smith</EndorseNumber>
                        </Flex>
        
        
                       
                       <Button primary marginTop={"16px"}>See All Stats</Button>
        
    </div>

</div>

                                    </div>

    </div>

</div>




</>)
}

const resume = {
    name: "John Doe",
    description: "Nullam viverra imperdiet diam sit amet tincidunt. Etiam dolor quam, suscipit facilisis ante id, molestie semper diam. Cras aliquet mauris non turpis imperdiet consequat. Nullam iaculis nisl sit amet velit aliquam vehicula. Donec placerat pellentesque lacus id pulvinar. Etiam non diam ut dui egestas dapibus sed vitae eros. Praesent sed ligula orci. Phasellus at placerat nulla. Ut dapibus pellentesque quam eget consectetur. Nunc et aliquet neque, scelerisque ullamcorper libero. Suspendisse et nisi neque. Donec sed mi in lacus viverra consequat nec scelerisque sem. Nam turpis nunc, ullamcorper ac commodo non, rhoncus ut odio. Maecenas mauris eros, scelerisque lobortis tristique tempor, ornare in tortor.",
    experience: [
        {role:"Master Carpenter", company:"Turner Construction", start_date:"Today", end_date:"Tomorrow", responsibilities:[
            "Mauris dictum elit ut orci imperdiet tempus.",
"Donec pharetra mauris vitae viverra pretium.",
"Integer dictum mauris ac elit iaculis aliquam.",
"Mauris vestibulum mi aliquet justo tempus ullamcorper.",
"Nam eget lectus sed est consectetur lacinia."
        ]},
        {role:"Carpenter", company:"Gilbane Construction", start_date:"Today", end_date:"Tomorrow", responsibilities:[
            "Mauris dictum elit ut orci imperdiet tempus.",
"Donec pharetra mauris vitae viverra pretium.",
"Integer dictum mauris ac elit iaculis aliquam.",
"Mauris vestibulum mi aliquet justo tempus ullamcorper.",
"Nam eget lectus sed est consectetur lacinia."
        ]},
        {role:"Carpenter Apprenctice", company:"Acme Construction", start_date:"Today", end_date:"Tomorrow", responsibilities:[
            "Mauris dictum elit ut orci imperdiet tempus.",
"Donec pharetra mauris vitae viverra pretium.",
"Integer dictum mauris ac elit iaculis aliquam.",
"Mauris vestibulum mi aliquet justo tempus ullamcorper.",
"Nam eget lectus sed est consectetur lacinia."
        ]}
    ]
}
const ResumeContainer = styled(Box)`
    padding: 16px;
`;
const ResumeName = styled.h3`
&&&{

    text-align: center;
    line-height: 2;
}
`;
const ResumeBio = styled.p`
&&&{

    line-height: 1.2;
    font-size: 16px;
    margin: 0 0 24px;
}
`;

const ExperienceContainer = styled(Box)`
    position: relative;
    color: #858f93;
    padding: 8px 0 16px;
`;
const ExperienceRole = styled(Box)`
    font-weight: 600;
    line-height: 1.5;
`;
const ExperienceCompany = styled(Box)`
line-height: 1.5;
`;
const ExperienceDate = styled(Box)`
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 14px;
`;

const Responsibilities = styled.ul`
 & li{
    list-style: circle;
    list-style-position: inside;
    padding: 8px 0;
    font-size: 14px;
}
`;

// const HomeLink = styled(NavLink)`
// &&&::before {
//     content: "H" !important;
// }
// `;

const Navigation = props => {

    useEffect(()=>{
        let youzifyNavigation = document.querySelector("#youzify-wall-nav ul");
        youzifyNavigation.firstElementNode.insertAdjacentElement("beforebegin", document.querySelector("#home-link"));
    },[]);

    // return <Router>
    //     <Route path='/' element={
    //         <li id="home-link"><HomeLink to="/">Home</HomeLink></li>
    //     }/>
    // </Router>

//return <li id="home-link"><HomeLink to="/">Home</HomeLink></li>

}


// const Resume = props => {
//     return (
//         <Widget title="Resume">
//             <ResumeContainer>
//             <ResumeName>{resume.name}</ResumeName>
//             <ResumeBio>{resume.description}</ResumeBio>
//             <Box className="experience">
//                 {resume.experience.map(exp => {
//                     return(<ExperienceContainer>
                        
//                         <ExperienceRole>{exp.role}</ExperienceRole>
//                         <ExperienceCompany>{exp.company}</ExperienceCompany>
//                         <ExperienceDate>{exp.start_date+" - "+exp.end_date}</ExperienceDate>
//                         <Responsibilities>
//                             {exp.responsibilities.map(resp => {
//                                 return <li>{resp}</li>
//                             })}
//                         </Responsibilities>
//                     </ExperienceContainer>)
//                 })}
//             </Box>
//             </ResumeContainer>
//         </Widget>
//     )
// }




window.onload = function(){

    console.log("Rendering App. v"+pkg.version)
    let aboutMe = document.querySelector(".youzify-about_me");
    if (aboutMe) {
        aboutMe.remove();
    }
    let page = document.querySelector("#page");
    let header = document.querySelector("#header");
    let navigation = document.querySelector("#youzify-wall-nav ul");
    let footer = document.querySelector("#footer");
    let sidebar = document.querySelector(".youzify-sidebar-column");
   // let mainColumn = document.querySelector(".youzify-main-column");
   // let profileNav = document.querySelector(".youzify-profile-navmenu");

    let widgetContainer = document.createElement("div");
    widgetContainer.id = "widget-container";
    let wallNavContainer = document.createElement("div");
    let jobListContainer = document.createElement("div");
    jobListContainer.id = "job-list";




    let welcomeModalContainer = document.createElement("div");
        welcomeModalContainer.classList.add("welcome-modal-container");
  
    let widgetRoot = createRoot(widgetContainer);
    let jobListRoot = createRoot(jobListContainer);

    let welcomeClosed = window.localStorage.getItem("workingpro-user");

    if (welcomeClosed) {
        welcomeClosed = JSON.parse(welcomeClosed);
    }

    if (page && welcomeClosed === null) {
        console.log("Displaying Welcome Message");
        page.appendChild(welcomeModalContainer);
        let modalRoot = createRoot(welcomeModalContainer);
        modalRoot.render(<WelcomeModal/>);
    }

    if (navigation) {
     //   let navigationContainer = document.createElement("div");

        //navigation.appendChild(navigationContainer);
      //  let navigationRoot = createRoot(navigationContainer);
    //    navigationRoot.render(<Navigation/>);

    navigation.innerHTML = `<style>#home-link a:before{content: "\\f015"; width: 32px; height: 32px; font-size: 14px; line-height: 32px; text-align: center; border-radius: 100%;}</style><li id="home-link"><a href="https://workingpro.co">Home</a></li>` + navigation.innerHTML;

   // document.querySelector("#home-link span").innerHTML = "\\f015";
    }


    if (sidebar) {
        sidebar.insertBefore(widgetContainer,sidebar.firstElementChild);
        widgetRoot.render(<JobsWidget/>)
    }
    
    if (header) {
        header.style.display = "block";
        page.style.paddingTop = "0px";
       // let headerContainer = document.createElement("div");
      //  header.appendChild(headerContainer);
    //  let headerRoot = createRoot(headerContainer);
      let headerRoot = createRoot(header);
        headerRoot.render(<Header/>)
    }

    if (footer) {
        footer.style.display = "block";
        let footerRoot = createRoot(footer);
        footerRoot.render(<Footer></Footer>)
    }


    if (true) {
        document.querySelector("#youzify-bp").insertBefore(wallNavContainer, document.querySelector("#youzify-bp").firstElementChild);
        let navRoot = createRoot(wallNavContainer);
        navRoot.render(<MainNav/>)
    }
    
    // let resumeContainer = document.createElement("div");
    // let resumeRoot = createRoot(resumeContainer);
    // if (document.querySelector(".youzify-skills")) {
    //     document.querySelector(".youzify-skills").parentNode.insertBefore(resumeContainer,document.querySelector(".youzify-skills"));        
    //   //  resumeRoot.render(<Resume/>)
    // }

    
}
