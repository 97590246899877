import * as React from 'react';
import Widget from '../components/Widget';
import {Box, Flex} from 'rebass';
import styled from 'styled-components';

import hardhat_icon from '../resources/images/hardhat_icon.png';
import electrician_icon from '../resources/images/electrician_icon.png';
import plumber_icon from '../resources/images/plumber_icon.png';
import carpenter_icon from '../resources/images/carpenter_icon.png';
import hvac_icon from '../resources/images/hvac_icon.png';

import {createRoot} from 'react-dom/client';

import {JobDetailModal} from './JobsDetail';
import Button from '../components/Button';

import { JobIcon, findJobTrade } from './JobsList';

const icons = {
    'electrician': electrician_icon,
    'plumber': plumber_icon,
    'carpenter': carpenter_icon,
    'hvac': hvac_icon
}

const SeeAllButon = styled(Box)`

`;

const JobsContainer = styled(Box)`
    max-height: 400px;
    overflow: scroll;
`;


const JobTitle = styled(Box)`
&&&{

    flex-grow:1;
    color: #5ac6ed; 
    font-weight: 600;
    margin-bottom: 8px;
    cursor: pointer;
}
`;
const JobSubtitle = styled(Box)`
    font-size:12px;
    text-transform: capitalize;
`;
const JobCompensation = styled(Box)`
font-size:12px;
`;
const MoreDetails = styled(Box)`
    color: #5ac6ed;
    font-size: 12px;
    cursor: pointer;
`;
const JobsWidget = (props) => {

    const [jobs, setJobs] = React.useState([]);

    React.useEffect(()=>{
        if (jobs.length === 0) {
            fetch("https://workingpro.co/wp-json/wp/v2/job").then(r => r.json()).then(data => {
                
            const regex = /per hour|\/hour/gi;
            const regex2 = /based on experience/gi;
  
            let newJobs = data.map(job => {
                let newJob = {};
                let iframe = document.createElement("iframe");
                iframe.style.width = "0px";
                iframe.style.height = "0px";
                iframe.srcdoc = job.content.rendered;
                document.body.appendChild(iframe);
                iframe.onload = ()=>{
                    let jobData = {...iframe.contentWindow.workingpro_job, ...job.meta,  id: job.id};
                    jobData.coordinates = jobData.coordinates.split(" ");
                    jobData.coordinates = [parseFloat(jobData.coordinates[1]),parseFloat(jobData.coordinates[0])]
                 //   jobData.coordinates[0] = parseFloat(jobData.coordinates[0]).toFixed(2);
                  //  jobData.coordinates[1] = parseFloat(jobData.coordinates[1]).toFixed(2);
                    if (typeof jobData.compensation === "object") {
                        jobData.compensation[1] = jobData.compensation[1].replace(regex, "/hr");
                    } else {
                        jobData.compensation = jobData.compensation.replace(regex2, "Experience Based");
                    }
                    jobData.original = iframe.contentDocument.body.innerHTML;
                    console.log(jobData);
                    setJobs((jobsArray) => {return [...jobsArray, jobData]})
                    iframe.remove()
                }
            })

            }).catch(e => console.error);
        }
    },[])

    let JobsDetailComponent = JobDetailModal;

    return <Widget title="Jobs" icon={<img src={hardhat_icon} style={{display:"inline-block",width:"35px",verticalAlign:"middle",marginRight:"10px"}}/>}>
    <JobsContainer padding={"24px"} paddingBottom={"60px"} fontSize={"14px"}>
        {jobs.map(job => {
            return <Flex padding={"10px 0"} borderBottom={"1px solid #ccc"} data-job-id={job.id}>
                 <JobIcon trade={findJobTrade(job)}/>
                <Box paddingLeft={"8px"} flexGrow={1}>
                    <JobTitle>{job.title}</JobTitle>
                    <JobSubtitle>
                        {job.employment_type+", "+job.location}
                    </JobSubtitle>
                </Box>
                <Flex flexDirection={"column"} justifyContent={"space-between"} height={"100%"} textAlign={"right"}>
                   
                        <JobCompensation>{typeof job.compensation === "object" ? job.compensation.join(" - ") : job.compensation}</JobCompensation>
                        <MoreDetails onClick={()=>{
                            // Hack to show jobs details 

                            let jobDetailContainer = document.createElement("div");
                            jobDetailContainer.id = "job-details-modal-container";
                            document.body.appendChild(jobDetailContainer);
                            let jobDetailModalRoot = createRoot(jobDetailContainer);


                            jobDetailModalRoot.render(<JobsDetailComponent {...job} trade={findJobTrade(job)}></JobsDetailComponent>)

                        }}>More details</MoreDetails>
              
                </Flex>
                
            </Flex>
        })}
        <Button primary style={{position:"absolute",bottom:"8px", left: "16px", width:"calc(100% - 32px)", fontSize:"14px", boxShadow:"0 0 8px rgba(255,255,255,1)"}} onClick={()=>{
            window.location.pathname = "/job-list"
        }}>See All Jobs</Button>
        </JobsContainer>
</Widget>
    }

export default JobsWidget;