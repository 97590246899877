
import LocationIcon from '../icons/location_icon.svg';
//import BlueLocator from '../icons/blue_locator.svg';
import BlueLocator from '../icons/blue_locator.png';
import CalendarIcon from '../icons/calendar_icon.svg';
import rightChevron from '../icons/right-chevron.svg';
// import awardIcon from '../icons/right-chevron.svg';
// import infoIcon from '../icons/right-chevron.svg';
import CertificatesIcon from '../icons/certificates.svg';
import RatingIcon from '../icons/rating.svg';
import DescriptionIcon from '../icons/description.svg';
import SkillsIcon from '../icons/skills.svg';
import WayfinderIcon from '../icons/wayfinder.svg';
import CarpenterIcon from '../icons/carpenter.svg';
import MasonIcon from '../icons/mason.svg';
import ElectricianIcon from '../icons/electrician.svg';
import PlumberIcon from '../icons/plumber.svg';
import PainterIcon from '../icons/painter.svg';
import BoilermakerIcon from '../icons/boilmaker.svg';
import FencebuilderIcon from '../icons/fence_builder.svg';
import FloorLayerIcon from '../icons/floor_layer.svg';
import StoneMasonIcon from '../icons/mason.svg';
import SteelworkerIcon from '../icons/iron_steel_worker.svg';
import PipefitterIcon from '../icons/septic_sewag.svg';
import CraneOperatorIcon from '../icons/septic_sewag.svg';
import ConstructionWorkerIcon from '../icons/construction_worker.svg';
import TaperIcon from '../icons/taper.svg';
import DrillerIcon from '../icons/driller.svg';
import CloseIcon from '../icons/close_icon.png';

const awardIcon = null;
const infoIcon = null;

const icons = [LocationIcon , BlueLocator, CalendarIcon, rightChevron, CertificatesIcon, RatingIcon, DescriptionIcon, SkillsIcon, WayfinderIcon, CarpenterIcon, MasonIcon, ElectricianIcon, PlumberIcon, PainterIcon, BoilermakerIcon, FencebuilderIcon, FloorLayerIcon, StoneMasonIcon, SteelworkerIcon, PipefitterIcon, CraneOperatorIcon, ConstructionWorkerIcon, TaperIcon, DrillerIcon, CloseIcon];

const getIcon = title =>{
    
    let filteredIcons = icons.filter(icon=>{
        return icon.toString().split("Icon")[0] === title ? true : false;
    });

    if(filteredIcons.length > 0){
        let icon = filteredIcons[0]
        console.log(icon)
    }

    return title == "Carpenter" ? CarpenterIcon : title == "Electrician" ? ElectricianIcon : title == "Mason" ? MasonIcon : title == "Stone Mason" ? MasonIcon : title == "Plumber" ? PlumberIcon : title == "Painter" ? PainterIcon : title == "Pipefitter" ? PipefitterIcon : title == "Steelworker" ? SteelworkerIcon : title == "Crane Operator" ? CraneOperatorIcon : title == "Boilermaker" ? BoilermakerIcon : title == "Driller" ? DrillerIcon : title == "Taper" ? TaperIcon : ConstructionWorkerIcon;
}

export {
    LocationIcon , BlueLocator, CalendarIcon, rightChevron, CertificatesIcon, DescriptionIcon, DescriptionIcon as Description, SkillsIcon, SkillsIcon as Skills,  WayfinderIcon, WayfinderIcon as Wayfinder,  CarpenterIcon, MasonIcon, ElectricianIcon, PlumberIcon, PainterIcon, BoilermakerIcon, FencebuilderIcon, FloorLayerIcon, StoneMasonIcon, SteelworkerIcon, PipefitterIcon, CraneOperatorIcon, ConstructionWorkerIcon, TaperIcon, DrillerIcon, CloseIcon ,
    getIcon
}

export {RatingIcon}