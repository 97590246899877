import * as React from 'react';
import styled , {css} from 'styled-components';

const StyledHeading = styled.h1`
  text-align:left;
  font-family: Roboto;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  padding: 0 0 2rem 0;
  margin:0;


  &:after{
    width: 70px;
    height: 5px;
    background:  #ffd900;
    content:"";
    display:block;
    margin-top:1rem;
  }

  ${props => props.primary && css`

    `}

  ${props => props.secondary && css`
    color:#fff;
    `}

    ${props => props.secondary && css`
   
    `}

    

`;

export default function Heading(props) {
  

  return (<StyledHeading {... props}/>);
}
