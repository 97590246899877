import * as React from 'react';
import styled , {css} from 'styled-components';
import {Box, Flex} from 'rebass';
import LoadingScreen from './LoadingScreen';

const StyledFooter = styled(Flex)`
    justify-content: space-between;
    padding: 16px;
`;

const Footer = (props) =>{
    React.useEffect(() => {
        if( document.querySelector("#loading-screen")){
            document.querySelector("#loading-screen").classList.add("hidden");
        }

        let youzifyModules = document.querySelectorAll("li.youzify_effect");
        youzifyModules.forEach(m => {
            m.classList.remove("youzify_effect")
        })
      }, [])


    return <StyledFooter>
        <Box>All rights reserved WorkingPro.co - 2023</Box>
        <a href='https://workingpro.co/privacy-policy'>Privacy Policy</a>
        {/* <LoadingScreen/> */}
    </StyledFooter>
}

export default Footer;