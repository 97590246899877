import * as React from 'react';
import styled from "styled-components";
import {Box, Flex} from 'rebass';
import Button from './Button';
import { LogoSymbol, LogoText, BetaStamp } from './Header';
import welcome_modal_background from '../resources/images/welcome_modal_background.jpg';

const ModalHeader = styled(Box)`
    background: #000;
    padding: 16px;
`;

const ModalContent = styled(Box)`
    padding: 32px 16px 32px;
`;

const StyledWelcomeModal = styled(Box)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fdd900;
    background-image: url('${welcome_modal_background}');
    background-size: cover;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 6px 8px 8px -4px rgb(0 0 0 / 80%);
    z-index: 999;
    font-weight: 600;
    font-size: 14px;
    max-width: 90%;
    width: 100%;

    @media screen and (min-width: 600px) {
        width: fit-content;
        max-width: 600px;
    }
   
`

const ModalOverlay = styled(Box)`

    display:block;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.75);
    top: 0;
    left: 0;
    z-index: 9;

`

export default function WelcomeModal(props){
    let modalRef = React.useRef();

    React.useEffect(() => {
        if (modalRef.current) {
            // modalRef.current.addEventListener("click", (e) => {
            //     window.localStorage.setItem("workingpro-user", JSON.stringify({onboarding: false, welcome: true, loggedIn: false}));
            //     e.currentTarget.remove();
            // })
        }
    })
    return <Box ref={modalRef}><ModalOverlay onClick={(e) => {
        window.localStorage.setItem("workingpro-user", JSON.stringify({onboarding: false, welcome: true, loggedIn: false}));
        e.currentTarget.parentNode.remove();
    }}/>
    <StyledWelcomeModal>
        <ModalHeader>
        <Flex maxWidth={"fit-content"} margin={"0 auto"} alignItems={"center"}>
            <LogoSymbol/>
             <LogoText style={{display:"block"}}/>
             <BetaStamp>Beta</BetaStamp>
            </Flex>
        </ModalHeader>
        <ModalContent>
            
        <Flex fontSize={"24px"} fontWeight={"600"} textAlign={"center"} padding={"16px 0"} maxWidth={"fit-content"} margin={"0 auto"} >Welcome to Working Pro!</Flex>
        <Box padding={"16px 0"}>We’re the networking platform for construction professionals. Get started
to access the following:</Box>
<ul style={{paddingLeft:"16px"}}>
   <li>Connect and build your network with other pros</li> 
   <li>Post photos of your work</li> 
   <li>Hire or find jobs</li> 
   <li>Gain points to access bonus features</li> 
</ul>
<Button style={{color:"#fff", background:"#000", fontFamily: "sans-serif", maxWidth:"150px", display:"block", margin: "0 auto", lineHeight: "2", margin:"32px auto 0", fontSize:"16px"}} action={() => {
    window.location = "https://workingpro.co/register/";
}} >Get started!</Button>
        </ModalContent>
    </StyledWelcomeModal>
    </Box>
}
